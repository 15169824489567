// Navigation

.nav-main {
	@include show-for(large);
	position: fixed;
	top: 0;
	left: 0;
	right: auto !important;
	width: 18.28%;
	min-height: 460px;

	@media (min-width: 2560px) {
		left: calc(50vw - 1280px) !important;
	}

	/* LOGO ------------------------------------------- */
	/* ------------------------------------------------ */
	.logo-holder {
		margin-top: rem-calc(45);

		a {
			display: inline-block;
			width: 100%;
			width: -webkit-fill-available;
		}
	}

	/* NAV -------------------------------------------- */
	/* ------------------------------------------------ */
	.nav-wrapper {
		text-align: right;

		.menu {
			margin-right: 15%;

			@include breakpoint(xxlarge) {
				margin-right: 16%;
			}

			& li a {
				color: get-color(primary);

				transition: color 250ms ease-in-sine;

				font-weight: 600;
				line-height: 1.2;
				letter-spacing: -0.01em;

				padding: rem-calc(6 10);

				&:hover {
					color: get-color(secondary);
				}
			}

			/* PRIMARY MENU =================================== */
			/* ================================================ */
			&.primary-menu {
				li {
					a {
						position: relative;
						overflow: hidden;

						font-size: responsive 15px 20px;
						font-range: 1024px 1920px;

						transition: padding 250ms ease-in-out-cubic;
					}

					&.is-active a:after,
					&:not(.is-active) a:before {
						content: '//';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						color: get-color(secondary);
					}

					&.is-active a,
					&:hover:not(.is-active) a {
						padding-right: 30px;

						@include breakpoint(xxlarge) {
							padding-right: 35px;
						}
					}

					&.is-active a:after,
					&:hover:not(.is-active) a:before {
						right: 10px;
					}

					&:not(.is-active) a:before {
						right: -20px;
						transition: right 250ms ease-in-out-cubic;
					}
				}
			}

			/* SOCIAL MENU ==================================== */
			/* ================================================ */
			&.social-menu {
				justify-content: flex-end;
				min-height: 50px;

				a {
					font-size: responsive 16px 22px;
					font-range: 1280px 1920px;
				}
			}
		}
	}

	/* HOME ------------------------------------------- */
	/* ------------------------------------------------ */
	.home & {
		width: 28.2%;

		.logo-holder {
			margin-top: rem-calc(150);
		}

		.nav-wrapper {
			.menu {
				& li a {
					color: $white;

					&:hover {
						color: get-color(secondary);
					}
				}
			}
		}
	}
}

.nav-mobile {
	@include hide-for(large);
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: auto !important;
	width: 21.77%;

	margin-top: rem-calc(15);

	/* LOGO ------------------------------------------- */
	/* ------------------------------------------------ */
	.logo-holder {
		width: 100%;
		margin-top: 10px;

		.thumbnail {
			margin-bottom: 0;
			border: none;
			box-shadow: none;
		}
	}

	/* HOME ------------------------------------------- */
	/* ------------------------------------------------ */
	.home & {
		@include breakpoint(portrait) {
			min-height: 215px;
			width: 65%;
		}
		@include breakpoint(landscape) {
			width: 36%;
			min-height: 168px;
		}

		.logo-holder {
			@include breakpoint(portrait) {
				margin-top: 20%;
			}
			@include breakpoint(landscape) {
				margin-top: 10px;
			}
		}
	}
}
