h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	&.is-style-bik-pink-dot {
		color: var(--wp--preset--color--blue);

		padding-right: 2rem;

		@include pink-circle-at-the-end;

		a {
			color: inherit;
			transition: color 250ms ease-in-sine;
			text-decoration: none;

			&:hover {
				color: var(--wp--preset--color--pink);
			}
		}
	}
	&.is-style-bik-blue-dot {
		padding-right: 1rem;

		&:after {
			content: '.';
			color: get-color(primary);

			font-size: responsive 28px 42px;
			font-range: 640px 1280px;
		}
		a:after {
			color: get-color(secondary);
		}

		a {
			color: get-color(primary);
			transition: color 250ms ease-in-sine;

			&:hover {
				color: get-color(secondary);
			}
		}
	}
}

h1,
.h1 {
	color: var(--wp--preset--color--pink);
	font-size: var(--wp--preset--font-size--gigantic);
	line-height: var(--wp--custom--line-height--page-title);
	
	margin-bottom: 0;
	padding-right: 1rem;

	&:after {
		content: '.';

		color: get-color(primary);
	}
}

h2,
.h2 {
	body:not(.home) & {
		margin-bottom: 0.8rem;
	}
}

h3,
.h3 {
	margin-bottom: 0.5rem;
}

h4,
.h4 {
}

h5,
.h5 {
}

h6,
.h6 {
}
