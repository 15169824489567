img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

/* Classic editor images */
.entry-content img {
	max-width: 100%;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object,
video {
	max-width: 100%;
}

/* Media captions */
figcaption,
.wp-caption,
.wp-caption-text,
.wp-block-embed figcaption {
	color: currentColor;
	font-size: var(--global--font-size-xs);
	line-height: var(--global--line-height-body);
	margin-top: calc(0.5 * var(--global--spacing-unit));
	margin-bottom: var(--global--spacing-unit);
	text-align: center;

	.alignleft &,
	.alignright & {
		margin-bottom: 0;
	}
}

/* fullscreen hero */
.fullscreen-hero img {
	position: absolute;

	top: 50%;
	right: 0;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -50%);

	max-width: 100%;
}

@supports (object-fit: cover) {
	.fullscreen-hero img {
		position: static;

		width: 100%;
		height: 100%;

		object-fit: cover;
		transform: none;
	}
}
