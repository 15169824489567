/* Over here, place any elements that do not need to have their own file. */
b,
strong {
	font-weight: 700;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

pre {
	white-space: pre;
	overflow-x: auto;
}

p + h3 {
	margin-top: 1.5rem;
}

.mb-0 {
	margin-bottom: 0;
}

.js .page-nos-projets .acf-block-figure,
.js .page-les-actus .acf-block-figure,
.js .page-nos-projets main,
.js .page-les-actus main,
.js .page-nos-projets .load-more-button,
.js .page-les-actus .load-more-button {
	visibility: hidden;
	opacity: 0;
}
