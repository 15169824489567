.wp-block-group {
  & p:last-child,
  li:last-child {
    margin-bottom: 0;
  }

  // Block Styles
  &.is-style-bik-border {
    border: 2px solid var(--wp--preset--color--blue);
    padding: 1em;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
