.wp-block-gallery {
	margin: 0 auto;

	&.is-style-bik-wide-gallery {
		&,
		.blocks-gallery-grid {
			width: 100%;
		}

		.blocks-gallery-grid {
			gap: 1em;

			margin-bottom: 1em;
		}

		.blocks-gallery-item {
			width: 100%;
			margin: 0;

			@media (min-width: 600px) {
				&:first-child {
					width: calc(55.5% - 0.5em);
				}
				&:not(:first-child) {
					width: calc(44.5% - 0.5em);
				}
			}
		}
	}
}
