/* -------------------------------------------------------------- */
/* Stylesheet: CONTENT Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.off-canvas-content {
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: [first] 1em [col2-start] 1fr [col2-end] 1em [end];
	margin: 0 rem-calc(15);
	min-height: 100vh;

	@include breakpoint(large) {
		grid-template-columns: [first] 4% [line2] 20% [col3-start] 1fr [col4-start] 1fr [line5] 4% [end];
	}
}

@media (min-width: 2560px) {
	body {
		background-color: var(--wp--preset--color--blue);
	}

	.off-canvas-content {
		max-width: 2560px;
		margin: 0 auto;
		background-color: var(--wp--preset--color--white);
	}
}

header {
	grid-row: 1 / 2;

	grid-column: col2-start / col2-end;

	@include breakpoint(large) {
		grid-column: col3-start / span 2;
	}

	body.home & {
		grid-column: first / end;
		margin: 0 rem-calc(-15);

		@media (min-width: 2560px) {
			margin: 0;
		}
	}
}

main {
	width: 100%;
	max-width: 1440px;
	margin: 2em auto 3.5em;

	grid-row: 2 / 3;
	grid-column: col2-start / col2-end;

	@include breakpoint(large) {
		grid-column: col3-start / span 2;
	}

	body.home & {
		margin-top: 0;
		padding-top: 2em;
		margin-bottom: 1em;
	}
}

footer {
	grid-column: first / end;
	grid-row: 3 / 4;
}
