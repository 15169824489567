.wp-block-acf-news-block {
	display: grid;
	gap: 1em;

	margin-bottom: 0.7em;

	@media (min-width: 600px) {
		grid-template-columns: repeat(3, 1fr);
	}

	figure {
		justify-self: center;

		a {
			display: inline-block;
			max-width: 100%;
			line-height: 0;

			margin-bottom: 0;

			@include pink-circle-plus;
			@include blue-filter;

			&:hover figcaption {
				opacity: 1;
				visibility: visible;
			}

			img {
				width: 100%;
				height: auto;
				aspect-ratio: 320/258.5;
			}

			figcaption {
				position: absolute;
				pointer-events: none;
				bottom: 0;
				left: 0;

				text-align: left;
	
				padding: 10px;

				p {
					font-size: responsive 15px 19px;
					font-range: 640px 1280px;
					font-weight: 600;
					line-height: 1.2;
					text-align: left;
		
					color: $white;

					margin-bottom: 0;
				}
	
				opacity: 0;
				visibility: hidden;
				transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	
				& > time {
					display: block;
					font-size: responsive 13px 16px;
					font-range: 640px 1280px;
					font-weight: 300;
					line-height: 1.2;
	
					color: $white;
				}
			}
		}
	}
}
