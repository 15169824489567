.wp-block-acf-archive-projects-block {
	margin-bottom: 2.5em;

	.archive-filter {
		margin-bottom: 2em;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		&__item {
			display: inline-block;
			border: solid 1px get-color(primary);
			color: get-color(primary);
			font-weight: bold;
			padding: 0.6rem 1rem;
			&:first-letter {
				text-transform: uppercase;
			}
			&.active {
				background-color: get-color(primary);
				color: white;
				pointer-events: none;
			}
			&:not(.active):hover {
				background-color: rgba(get-color(primary), 0.1);
			}
		}
	}

	.acf-block-grid {
		display: grid;
		gap: 0.5em;
		margin-bottom: 0.5em;

		@include breakpoint(medium) {
			gap: 1.25em;
			margin-bottom: 1.25em;
		}

		// &:nth-child(odd) {
		// 	// grid-template-columns: 53.334% auto;
		// 	grid-template-columns: repeat(2, 1fr);
		// }

		// &:nth-child(even) {
		// 	grid-template-columns: repeat(3, 1fr);
		// }
		&[data-grid='odd'] {
			grid-template-columns: repeat(2, 1fr);
		}

		&[data-grid='even'] {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.acf-block-figure {
		// height: 100%;
		position: relative;
		width: 100%;
		height: 0;
		// max-height: 512px;
		padding-bottom: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		a {
			position: absolute !important;
			top: 0;
			left: 0;
			display: inline-block;
			height: 100%;
			width: 100%;
			line-height: 0;

			margin-bottom: 0;

			@include pink-circle-plus;
			@include blue-filter;

			&:after {
				@include breakpoint(small only) {
					top: 10px;
					right: 10px;
					left: auto;
					transform: translate(0, 0);

					height: 25px;
					width: 25px;
				}
			}

			&:hover:after {
				opacity: 1;
				visibility: visible;
			}

			&:hover figcaption {
				opacity: 1;
				visibility: visible;
			}

			figcaption {
				position: absolute;
				pointer-events: none;
				bottom: 0;
				left: 0;

				color: var(--wp--preset--color--white);

				line-height: 1.2;
				text-align: left;

				padding: 10px;

				opacity: 0;
				visibility: hidden;
				transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

				h2 {
					font-family: $body-font-family;
					font-size: responsive 15px 19px;
					font-range: 640px 1920px;
					font-weight: 600;
					line-height: inherit;

					margin-bottom: 0;
				}
				p {
					@media (max-width: 599px) {
						display: none;
					}
					font-size: responsive 13px 16px;
					font-range: 640px 1920px;
					font-weight: 300;
					line-height: inherit;

					margin-bottom: 0;

					span:not(:last-child):after {
						content: ' - ';
					}
				}
			}
		}
	}
}
