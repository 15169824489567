.wp-block-acf-gallery-color-block {
	& > div {
		display: grid;
		gap: 1.1em;
	}

	.color-block {
		display: grid;
		align-items: center;

		height: 100%;

		background-color: var(--wp--preset--color--blue);

		div {
			p {
				color: var(--wp--preset--color--white);
				font-size: 0.9em;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	.grid-1 {
		grid-template-columns: repeat(2, 1fr);

		@media (min-width: 600px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (min-width: 782px) {
			grid-template-columns: repeat(5, 1fr);
		}
		@media (min-width: 1440px) {
			grid-template-columns: repeat(7, 1fr);
		}

		.color-block {
			padding: 1em;

			div {
				height: 100%;
			}

			p {
				font-size: clamp(0.9rem, calc(0.8769rem + 0.1026vw), 1rem);
				letter-spacing: -0.05em;
				margin-bottom: 0.5rem;
			}

			p strong {
				font-weight: 400;
			}

			h2 {
				color: var(--wp--preset--color--pink);
				font-size: clamp(1.2em, calc(1.0615em + 0.6154vw), 1.8em);
			}

			&.block-2 {
				div {
					position: relative;
					padding-bottom: 1.5em;
				}

				p:last-child {
					position: absolute;
					right: 0;
					bottom: 0;

					a {
						color: var(--wp--preset--color--white);
						transition: color 250ms ease-in-sine;
						text-decoration: none;

						font-family: $header-font-family;
						font-size: var(--wp--preset--font-size--medium-plus);

						&:after {
							content: '.';
							color: var(--wp--preset--color--pink);
							padding-left: 0.2rem;

							font-size: responsive 28px 42px;
							font-range: 640px 1280px;
						}

						&:hover {
							color: var(--wp--preset--color--pink);
						}
					}
				}
			}
			@media (max-width: 599px) {
				min-height: 120px;

				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 2;
				}
				&.block-2 {
					grid-column: 1 / 3;
					grid-row: 7 / 9;
				}
			}
			@media (min-width: 600px) and (max-width: 781px) {
				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 4;
				}
				&.block-2 {
					grid-column: 3 / 5;
					grid-row: 6 / 8;
				}
			}
			@media (min-width: 782px) and (max-width: 1439px) {
				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 4;
				}
				&.block-2 {
					grid-column: 4 / 6;
					grid-row: 4 / 7;
				}
			}
			@media (min-width: 1440px) {
				&.block-1 {
					grid-column: 1 / 4;
					grid-row: 1 / 3;
				}
				&.block-2 {
					grid-column: 6 / 8;
					grid-row: 3 / 5;
				}
			}
		}
	}

	.grid-2 {
		grid-template-columns: repeat(2, 1fr);

		@media (min-width: 600px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (min-width: 782px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (min-width: 1280px) {
			grid-template-columns: repeat(5, 1fr);
		}

		.color-block {
			padding: 0.5em 1em;

			p {
				color: var(--wp--preset--color--white);
				font-size: 0.9em;
				line-height: 0.9;
				margin-bottom: 0.75rem;
			}

			p:first-child strong {
				font-family: $header-font-family;
				font-size: var(--wp--preset--font-size--extra-large);
				font-weight: $header-font-weight;
			}

			em {
				font-size: 0.7em;
			}

			@media (max-width: 599px) {
				min-height: 120px;

				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 2;
				}
				&.block-2 {
					grid-column: 1 / 3;
					grid-row: 5 / 6;
				}
				&.block-3 {
					grid-column: 1 / 3;
					grid-row: 9 / 10;
				}
			}

			@media (min-width: 600px) and (max-width: 781px) {
				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 2;
				}
				&.block-2 {
					grid-column: 2 / 4;
					grid-row: 4 / 5;
				}
				&.block-3 {
					grid-column: 1 / 3;
					grid-row: 7 / 8;
				}
			}

			@media (min-width: 782px) and (max-width: 1279px) {
				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 2;
				}
				&.block-2 {
					grid-column: 3 / 5;
					grid-row: 3 / 4;
				}
				&.block-3 {
					grid-column: 2 / 4;
					grid-row: 6 / 7;
				}
			}

			@include breakpoint(xlarge) {
				&.block-1 {
					grid-column: 1 / 3;
					grid-row: 1 / 2;
				}
				&.block-2 {
					grid-column: 3 / 6;
					grid-row: 3 / 4;
				}
				&.block-3 {
					grid-column: 2 / 4;
					grid-row: 5 / 6;
				}
			}
		}
	}

	figure {
		position: relative;
		width: 100%;
		padding-top: 100%;

		margin: 0;

		@include blue-filter;

		img {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&:before {
			z-index: 1;
		}

		&:hover:before {
			opacity: 1;
			visibility: visible;
		}

		&:hover figcaption {
			opacity: 1;
			visibility: visible;
		}

		figcaption {
			position: absolute;
			z-index: 2;
			pointer-events: none;
			bottom: 0;
			left: 0;
			width: 100%;

			color: var(--wp--preset--color--white);
			text-align: center;

			padding: 0.5em;
			margin-bottom: 1em;

			opacity: 0;
			visibility: hidden;
			transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

			p {
				font-size: var(--wp--preset--font-size--small);
				margin-bottom: 0;

				strong {
					font-weight: 600;
				}
			}
		}
	}
}
