.wp-block-quote {
  border-left: none;

  .wp-block-quote__citation,
  cite,
  footer {
    .has-background &,
    [class*='background-color'] &,
    [style*='background-color'] &,
    .wp-block-cover[style*='background-image'] & {
      color: currentColor;
    }
  }

  &.is-large,
  &.is-style-large {
    position: relative;
    height: 100%;

    padding-left: 0;
    padding-right: 0;

    p {
      font-family: $header-font-family;
      font-size: var(--wp--preset--font-size--large);
      font-style: normal;
      font-weight: 400;
      line-height: var(--wp--custom--line-height--heading);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.has-text-align-left {
      padding-right: 40px;

      &:after {
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.09 25.09'%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3Cpath class='a' d='M50.27,65.81A12.55,12.55,0,1,0,37.72,53.27,12.55,12.55,0,0,0,50.27,65.81' transform='translate(-37.72 -40.72)'/%3E%3C/svg%3E");

        width: 25px;
        height: 25px;
        right: 0;
        bottom: 0;
      }
    }
    &.has-text-align-right {
      padding-left: 40px;

      &:after {
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.09 25.09'%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3Cpath class='a' d='M50.27,65.81A12.55,12.55,0,1,0,37.72,53.27,12.55,12.55,0,0,0,50.27,65.81' transform='translate(-37.72 -40.72)'/%3E%3C/svg%3E");

        width: 25px;
        height: 25px;
        left: 0;
        bottom: 0;
      }
    }

    .wp-block-quote__citation,
    cite,
    footer {
      color: currentColor;
      font-size: var(--wp--preset--font-size-small);
    }
  }
}
