/* -------------------------------------------------------------- */
/* Stylesheet: GLOBAL Stylesheet ------------------ */
/* -------------------------------------------------------------- */
:root {
	--separator--height: 1px;
}

* {
	-webkit-tap-highlight-color: transparent !important;
	overflow-anchor: none;

	&:focus {
		outline: none;
	}
}
