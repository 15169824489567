[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
	display: block;
	box-sizing: border-box;
	width: 100%;
	appearance: none;
}

.wp-block-contact-form-7-contact-form-selector {
	& #wpcf7-f11-o1 form {
		.form-grid {
			display: grid;
			grid-template-columns: 1fr auto;

			border-bottom: 1px solid $white;

			@include breakpoint(medium) {
				max-width: 275px;
			}

			/* INPUT : EMAIL ================================== */
			/* ================================================ */
			.text-input {
				input {
					height: 36px;

					padding: 0.5rem 0;
					margin: 0;

					color: $white;
					background-color: transparent;
					border: none;
					box-shadow: none;

					&::placeholder {
						color: white;
						font-size: rem-calc(16);
						font-weight: 500;
					}
				}
			}

			/* SUBMIT BUTTON ================================== */
			/* ================================================ */
			.submit-area {
				position: relative;

				button {
					width: 50px;
					height: 36px;

					margin: 0;
					padding: 0.25rem;

					background-color: transparent;
					transition: background-color 250ms ease-in-sine;

					&:hover {
						background-color: get-color(primary);
					}
				}

				/* AJAX LOADER ==================================== */
				/* ================================================ */
				.ajax-loader {
					position: absolute;
					right: -16px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		/* VALIDATION ERRORS ============================== */
		/* ================================================ */
		&.invalid .wpcf7-response-output,
		&.unaccepted .wpcf7-response-output {
			color: $primary-color;
			background-color: $white;

			border-color: get-color(primary);

			margin-left: 0;
			margin-right: 0;
		}

		/* VALIDATION NOT VALID =========================== */
		/* ================================================ */
		span.wpcf7-not-valid-tip {
			position: absolute;

			left: 0;
			width: 275px;

			padding-top: 0.25rem;

			color: get-color(primary);

			font-size: 11px;
			font-weight: 700;
		}
	}
	& #wpcf7-f888-o2 form {
		.text-input,
		.text-area {
			border-style: dotted;
			border-color: var(--wp--preset--color--blue);
			border-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8"%3F%3E%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.5 2"%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23009bb5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px;stroke-dasharray:0 3;%7D%3C/style%3E%3C/defs%3E%3Cline class="a" x1="1" y1="1" x2="96" y2="1"/%3E%3C/svg%3E')
				0 0 100 0 repeat repeat;
			padding: 0.5rem 0.5rem 0.5rem 0;

			label {
				font-size: var(--wp--preset--font-size--medium);

				input,
				textarea {
					font-size: var(--wp--preset--font-size--normal);
					font-weight: 300;
					width: 100%;
				}
				input[type='tel'],
				input[type='email'],
				input[type='text'],
				textarea {
					border-left: 0 !important;
					border-right: 0 !important;
					border-top: 0 !important;
					border-bottom: 0 !important;
				}
				span.wpcf7-not-valid-tip {
					position: absolute;
					font-weight: bolder;
					color: var(--wp--preset--color--pink);
					font-size: 12px;
					bottom: -35px;
					left: 0;
				}
			}
		}
		.text-input {
			margin-bottom: 3.5em;

			label {
				position: relative;

				& > span:first-of-type {
					display: inline-block;
					width: 90px;
				}
				& > span.wpcf7-form-control-wrap {
					display: inline-block;
					width: calc(100% - 90px);
				}
			}
		}
		.text-area {
			margin-bottom: 2.5em;

			textarea {
				padding-top: 0.5rem;
				min-height: 250px;
			}
		}
		.submit-area {
			position: relative;
			width: 100%;
			display: inline-flex;
			justify-content: space-between;
			align-items: center;

			& > span:first-of-type {
				padding-right: 10px;
			}
			button {
				font-size: var(--wp--preset--font-size--medium);
				font-weight: 700;
				padding: 0.5rem;
				margin-bottom: 0;
				word-break: normal;
			}
			.ajax-loader {
				position: absolute;
				right: -16px;
				top: 50%;
				transform: translateY(-50%);
			}
			.wpcf7-acceptance .wpcf7-list-item {
				margin: 0;

				.wpcf7-list-item-label {
					font-size: var(--wp--preset--font-size--small);
					margin-left: 0.5rem;
				}
			}
		}

		&.invalid .wpcf7-response-output,
		&.unaccepted .wpcf7-response-output {
			color: var(--wp--preset--color--blue);
			background-color: transparent;
			border: 2px solid var(--wp--preset--color--blue);
		}
	}
}
