/* -------------------------------------------------------------- */
/* Stylesheet: OFF CANVAS Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.off-canvas {
  transition-delay: 0.9s;
  transition-property: transform;
  
  padding: 0;
  text-align: right;
  height: 100%;

  overflow: hidden;

  color: get-color(primary);


	&.is-open {
		transition-delay: 0s;
		transition-property: transform;
  }
  
  .nav-overlay-logo {
    position: fixed;
    top: 15px;
    width: 25%;

    padding-top: 10px;

    .thumbnail {
      margin-bottom: 0;
      border: none;
      box-shadow: none;
    }
  }
  
  .nav-overlay-bg {
    width: 1em;
    height: 1em;
    font-size: 4rem;
    background: get-color(secondary);
    border-radius: 50%;
    position: fixed;
    right: 1rem;
    top: 1rem;
    transform-origin: center center;
    transform: scale(0);
    transition: transform .8s cubic-bezier(.86,0,.07,1);
  }

  .nav-overlay-menu {
    padding-top: rem-calc(90);
    padding-bottom: rem-calc(60);
    
    height: 100%;
    width: 100%;
    
    div {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      
      @include breakpoint(portrait) {
        justify-content: center;
      }
    }
 
    .menu {
      margin-left: auto;
      margin-right: auto;
      width: 78vw;

      & li a {
        position: relative;
        z-index: 1600;
        color: inherit;

        transition: color 250ms ease-in-sine, opacity .4s linear;;

        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.01em;

        padding: rem-calc(10);
        
        &:hover {
          color: $white;
        }
      }
      
      /* PRIMARY MENU =================================== */
      /* ================================================ */
      &.primary-menu {

        li {
          position: relative;
          z-index: 1300;
          transition: padding 250ms ease-in-out-cubic;
          overflow: hidden;

          &.is-active {
            color: $white;
          }

          &.is-active:after,
          &:not(.is-active):before {
            content: "//";

            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            color: $white;

            font-family: $body-font-family;
            font-size: responsive 18px 26px;
            font-range: 1280px 1920px;
            font-weight: 600;
            line-height: 1.2;
          }

          &.is-active,
          &:hover:not(.is-active) {
            padding-right: 26px;
          }

          &.is-active:after,
          &:hover:not(.is-active):before {
            right: 10px;
          }

          &:not(.is-active) {
            &:before {
              right: -20px;

              transition: right 250ms ease-in-out-cubic;
            }
          }

          a {
            font-size: responsive 24px 30px;
            font-range: 360px 640px;
          }
        }
      }

      /* SOCIAL MENU ==================================== */
      /* ================================================ */
      &.social-menu {
        justify-content: flex-end;
        min-height: 35px;

        @include breakpoint(landscape) {
          min-height: 55px;
        }

        a {
          font-size: responsive 22px 28px;
          font-range: 360px 640px;
        }
      }
    }
  }
}