.single {
	header {
		figure {
			position: relative;
			padding-bottom: 62.20%;
			height: 0;

			margin-bottom: 2em;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			figcaption {
				position: absolute;
				top: 100%;
				right: 0;

				color: rgba($black, 0.5);

				font-size: responsive 10px 14px;
				font-range: 640px 1280px;
				font-weight: 300;

				text-align: right;

				margin-top: 0.5rem;
			}
		}
		.orbit {
			.orbit-container {
				padding: 0;

				.orbit-figure {
					margin-bottom: 0;
				}
			}
			.orbit-bullets {
				text-align: right;
				line-height: 1;
				font-size: 0.8rem;
			}
		}
	}

	main {
		hr {
			margin: 2rem auto;
		}
		.article-similar {
			h2 {
				margin-bottom: 1.5rem;
			}

			& > div {
				display: grid;
				gap: 1em;
				grid-template-columns: repeat(3, 1fr);

				figure {
					justify-self: center;
					&,
					& img {
						width: 100%;
					}

					img {
						max-width: none;
						object-fit: cover;
					}

					a {
						display: inline-block;
						max-width: 100%;
						width: 100%;
						line-height: 0;

						margin-bottom: 0;

						@include pink-circle-plus;
						@include blue-filter;

						@include breakpoint(small only) {
							&:after {
								opacity: 1;
								visibility: visible;
								top: 5px;
								right: 5px;
								left: auto;
								transform: translate(0, 0);

								height: 15px;
								width: 15px;
							}
						}

						&:hover figcaption {
							opacity: 1;
							visibility: visible;
						}

						figcaption {
							@include show-for(medium);
							position: absolute;
							pointer-events: none;
							bottom: 0;
							left: 0;

							color: $white;

							line-height: 1.2;
							text-align: left;

							padding: 10px;

							opacity: 0;
							visibility: hidden;
							transition: all 300ms
								cubic-bezier(0.445, 0.05, 0.55, 0.95);

							h3 {
								font-family: $body-font-family;
								font-size: responsive 15px 19px;
								font-range: 640px 1920px;
								font-weight: 600;
								line-height: inherit;

								margin-bottom: 0;
							}

							p {
								font-size: responsive 13px 16px;
								font-range: 640px 1920px;
								font-weight: 300;
								line-height: inherit;

								margin-bottom: 0;

								span:not(:last-child):after {
									content: ' - ';
								}
							}
						}
					}
				}
			}
		}
	}
}
.single-actualites {
	header h1 {
		margin-bottom: 0.25rem;
	}
}
.single-projets {
	main {
		margin-top: 0.5em;
		margin-bottom: 5em;

		.article-details {
			display: grid;
			gap: 2em;

			@include breakpoint(medium) {
				grid-template-columns: 1fr minmax(auto, auto);
				align-items: end;
			}

			p {
				margin-bottom: 0;

				&:last-child {
					color: get-color(primary);

					font-size: responsive 14px 18px;
					font-range: 640px 1920px;
					font-weight: 300;
					text-align: right;

					margin-top: rem-calc(12);

					@include breakpoint(medium) {
						margin-top: 0;
					}

					span:not(:last-child):after {
						content: ' - ';
					}
				}
			}
		}

		.article-table {
			display: grid;
			gap: 20px;

			@include breakpoint(medium) {
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			}

			@include breakpoint(xlarge) {
				column-gap: 50px;
				grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
			}

			.item-label,
			.item-field {
				font-size: 15px;
				line-height: 1.3;

				margin-bottom: 0;
			}

			.item-label {
				color: get-color(primary);

				font-family: $body-font-family;

				strong {
					font-weight: 600;
				}
			}
		}
	}

	.article-gallery {
		margin-bottom: 2em;
		& .gallery-container {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 1em;
			margin-bottom: 1em;

			&.grid-column-10 {
				grid-template-columns: repeat(10, 1fr);
			}

			& > div {
				min-height: 100%;
			}
			& > .small-grid-col-2 {
				grid-column: auto / span 2;
			}
			& > .small-grid-col-3 {
				grid-column: auto / span 3;
			}
			& > .small-grid-col-4 {
				grid-column: auto / span 4;
			}
			& > .small-grid-col-6 {
				grid-column: auto / span 6;
			}
			& > .small-grid-col-8 {
				grid-column: auto / span 8;
			}
			& > .small-grid-col-10 {
				grid-column: auto / span 10;
			}
			& > .small-grid-col-12 {
				grid-column: auto / span 12;
			}
			@include breakpoint(medium) {
				& > .medium-grid-col-2 {
					grid-column: auto / span 2;
				}
				& > .medium-grid-col-3 {
					grid-column: auto / span 3;
				}
				& > .medium-grid-col-4 {
					grid-column: auto / span 4;
				}
				& > .medium-grid-col-6 {
					grid-column: auto / span 6;
				}
				& > .medium-grid-col-8 {
					grid-column: auto / span 8;
				}
				& > .medium-grid-col-12 {
					grid-column: auto / span 12;
				}
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
