/*
 * text-underline-offset doesn't work in Chrome at all 👎
 * But looks nice in Safari/Firefox, so let's keep it and
 * maybe Chrome will support it soon.
 */
a {
	cursor: pointer;
}

.load-more-button {
	text-align: center;

	margin-top: 3em;

	a {
		position: relative;
		padding: rem-calc(8 10 8 35);

		transition: color 0.25s ease;

		&:hover {
			color: var(--wp--preset--color--blue);
		}

		&:after,
		&:before {
			position: absolute;

			top: 50%;
			left: 0;
			transform: translate(0, -50%);

			height: 35px;
			width: 35px;
			transition: all 250ms ease-in-quart;
		}
		&:after {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.12 35.12'%3E%3Cpath d='M18,35.56A17.56,17.56,0,1,0,.44,18,17.56,17.56,0,0,0,18,35.56' transform='translate(-0.44 -0.44)' fill='%23f5b5d3'/%3E%3Cpath d='M25.11,19.27H19.33V25H16.67V19.27H10.89V16.76h5.78V11.05h2.66v5.71h5.78Z' transform='translate(-0.44 -0.44)' fill='%23009ab2'/%3E%3C/svg%3E");
			visibility: visible;
			opacity: 1;
		}
		&:before {
			content: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 35 35' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Ccircle cx='17.5' cy='17.5' r='17' fill='%23f5b5d3'/%3E%3Crect x='11.51' y='10.01' width='2' height='4.99' fill='%23009cb5'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 10; 0 0' begin='0' dur='0.6s' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='16.5' y='10.01' width='2' height='4.99' fill='%23009cb5'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 10; 0 0' begin='0.2s' dur='0.6s' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='21.49' y='10.01' width='2' height='4.99' fill='%23009cb5'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 10; 0 0' begin='0.4s' dur='0.6s' repeatCount='indefinite' /%3E%3C/rect%3E%3C/svg%3E");
			visibility: hidden;
			opacity: 0;
		}
		&.loading:after {
			visibility: hidden;
			opacity: 0;
		}
		&.loading:before {
			visibility: visible;
			opacity: 1;
		}
	}
}
