/* -------------------------------------------------------------- */
/* Stylesheet: WINDOW BORDER Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.window-border {
	span {
		position: fixed;
		background-color: get-color(primary);

		&.top-border,
		&.bottom-border {
			z-index: 11;
			left: 0;
			right: 0;
			height: 15px;
		}

		&.top-border {
			top: 0;
		}

		&.bottom-border {
			bottom: 0;
		}

		&.left-border,
		&.right-border {
			z-index: -2;
			top: 0;
			bottom: 0;
			width: 15px;
		}

		&.left-border {
			left: 0;
		}

		&.right-border {
			right: 0;
		}
	}

	/* HOME ------------------------------------------- */
	/* ------------------------------------------------ */
	.home & span.top-border,
	.home & span.bottom-border {
		visibility: hidden;
		opacity: 0;
	}
}
