hr {
	border-style: none;
	border-bottom: var(--separator--height) solid var(--wp--preset--color--blue);
	border-top: 0 !important;

	&.wp-block-separator {
		border-bottom: var(--separator--height) solid var(--wp--preset--color--blue);
		opacity: 1;

		&.is-style-bik-blue-dotted-line {
			border-style: dotted;
			border-color: var(--wp--preset--color--blue);
			border-width: 2px;
			border-image-source: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 2'%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23009cb5;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;stroke-dasharray:0 6;%7D%3C/style%3E%3Cline class='a' x1='1' y1='1' x2='99' y2='1'/%3E%3C/svg%3E");
			border-image-width: 1;
			border-image-slice: 2;
			border-image-repeat: round;
		}
		&.is-style-bik-white-dotted-line {
			border-style: dotted;
			border-color: var(--wp--preset--color--white);
			border-width: 2px;
			border-image-source: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 2'%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;stroke-dasharray:0 6;%7D%3C/style%3E%3Cline class='a' x1='1' y1='1' x2='99' y2='1'/%3E%3C/svg%3E");
			border-image-width: 1;
			border-image-slice: 2;
			border-image-repeat: round;
		}

		.has-background &,
		[class*="background-color"] &,
		[style*="background-color"] &,
		.wp-block-cover[style*="background-image"] & {
			border-color: currentColor;
		}
	}
}
