@charset 'utf-8';

@import '01-settings/settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
//@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
//@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
//@include foundation-button-group;
//@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-callout;
//@include foundation-card;
@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
//@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
//@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

/* Categories 01 to 03 are the basics. */
@import '01-settings/global';

@import '02-tools/mixins';
@import '02-tools/functions';

//@import "03-generic/normalize";
//@import "03-generic/breakpoints";
//@import "03-generic/vertical-margins";
//@import "03-generic/reset";
//@import "03-generic/animations";

/* Category 04 can contain any default HTML element. Do not add classes here, just give the elements some basic styles. */
@import '04-elements/burger';
@import '04-elements/media';
@import '04-elements/forms';
@import '04-elements/links';
@import '04-elements/misc';
@import '04-elements/window-border';

/* Category 05 is all about adjusting the default block styles to the given layout. I only added three blocks as examples. */
@import '05-blocks/blocks';

/* Category 05 is all about adjusting the default block styles to the given layout. I only added three blocks as examples. */
@import '06-acf-blocks/acf-blocks';

/* Category 07 contains all "bigger" components which contain elements of the previous two categories like header, footer, page template, single template, archives, ... */
@import '07-components/header';
@import '07-components/footer';
@import '07-components/single';
@import '07-components/entry';
@import '07-components/navigation';
@import '07-components/off-canvas';
//@import "06-components/404";

/* Category 08 is for any utility classes that are not assigned to a specific component. */
@import '08-utilities/midnight';
