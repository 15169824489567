/* -------------------------------------------------------------- */
/* Stylesheet: BURGER Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.burger-wrapper {
	@include hide-for(large);
	position: fixed;
	top: 0;
	left: auto !important;
	width: 80px;
	right: 0;
	z-index: 100;
	height: 60px;

	margin-top: rem-calc(15);

	.burger-content {
		position: relative;
		height: 60px;
		overflow: hidden;

		.burger {
			position: absolute;
			right: 25px;
			top: 10px;
			display: inline-block;
			cursor: pointer;

			// Normalize (<button>)
			font: inherit;
			color: inherit;
			text-transform: none;
			background-color: transparent;
			border: 0;
			margin: 0;
			overflow: visible;

			.burger-svg {
				.burger-inner {
					fill: none;
					stroke-linecap: round;
					stroke-width: 4;
					transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
						stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
				}

				.line1,
				.line3 {
					stroke-dasharray: 22.5 82.25;
				}

				.line2 {
					stroke-dasharray: 22.5 22.5;
				}
			}
		}
	}

	/* IS OFF CANVAS OPEN ----------------------------- */
	/* ------------------------------------------------ */
	.off-canvas.is-open ~ & .burger .burger-svg {
		.burger-inner {
			stroke: $white !important;
		}

		.line1,
		.line3 {
			stroke-dasharray: 30 82.25;
			stroke-dashoffset: -52.5;
		}

		.line2 {
			stroke-dasharray: 1 22.5;
			stroke-dashoffset: -11.25;
		}
	}
}
