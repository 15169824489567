p {
  // inherits general font style set at <body>
  &.has-background {
    padding: 1em;
  }

  // Override `color: inherit` from Core styles.
  &.has-text-color a {
    color: var(--wp--style--color--link);
  }

  &.is-style-bik-pink-dot {
    @include pink-circle-at-the-end;

	font-size: var(--wp--preset--font-size--medium-plus);
	line-height: var(--wp--custom--line-height--body);

    a {
      color: var(--wp--preset--color--black);
      transition: color 250ms ease-in-sine;
	  text-decoration: none;

      &:hover {
        color: var(--wp--preset--color--blue);
      }
    }
  }
}
