ul,
ol {
  font-family: var(--list--font-family);
  margin: 0;
  padding-left: 1em;

  // Utility classes
  &.aligncenter {
    list-style-position: inside;
    padding: 0;
  }

  &.alignright {
    list-style-position: inside;
    text-align: right;
    padding: 0;
  }
}

ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;
  }

  &.is-style-bik-blue-circle {
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    li::marker {
      content: '\2022  ';
      color: var(--wp--preset--color--blue);
      font-size: 1em;
      font-weight: bold;
    }
  }
}

ol {
  list-style-type: decimal;

  ul {
    list-style-type: circle;
  }
}
