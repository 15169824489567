.wp-block-acf-archive-news-block {
	margin-bottom: 2.5em;

	.acf-block-grid {
		display: grid;
		gap: 1em;

		@media (min-width: 480px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 781px) {
			grid-template-columns: repeat(3, 1fr);
		} 

		& + .acf-block-grid { 
			margin-top: 1em;
		}
	}

	figure {
		img {
			width: 100%;
			height: auto;
			object-fit: cover;
			aspect-ratio: 320/258.5
		}

		a {
			display: inline-block;
			height: 100%;
			width: 100%;
			line-height: 0;

			margin-bottom: 0;

			@include pink-circle-plus;
			@include blue-filter;

			&:after {
				@include breakpoint(small only) {
					top: 10px;
					right: 10px;
					left: auto;
					transform: translate(0, 0);

					height: 25px;
					width: 25px;
				}
			}

			&:hover:after {
				opacity: 1;
				visibility: visible;
			}

			&:hover figcaption {
				opacity: 1;
				visibility: visible;
			}

			figcaption {
				position: absolute;
				pointer-events: none;
				bottom: 0;
				left: 0;

				color: var(--wp--preset--color--white);

				line-height: 1.2;
				text-align: left;

				padding: 10px;

				opacity: 0;
				visibility: hidden;
				transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

				h2 {
					color: inherit;
					font-family: $body-font-family;
					font-size: clamp(0.9rem, calc(0.8538rem + 0.2051vw), 1.1rem);
					font-weight: 600;
					line-height: inherit;

					margin-bottom: 0;
				}
			}
		}
	}

	.color-block {
		aspect-ratio: 320/258.5;

		a {
			display: grid;
			align-items: flex-end;
			height: 100%;

			background-color: var(--wp--preset--color--pink);

			text-align: right;

			padding: 0.5em 1em;

			transition: all cubic-bezier(0.445, 0.05, 0.55, 0.95);

			&:hover {
				background-color: var(--wp--preset--color--blue);

				h2 {
					color: var(--wp--preset--color--pink) !important;
				}
			}
		}
	}
}
