.wp-block-columns {
	@media (max-width: 599px) {
		.wp-block-column:first-child {
			margin-bottom: 1em;
		}
	}

	.wp-block-column {
		&.has-background {
			padding: 1.1em;
		}

		& > * {
			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.is-style-bik-order-columns {
		@media (max-width: 599px) {
			.wp-block-column {
				order: 2;
				margin-bottom: 0;

				&:not(:first-child) {
					order: 1;
					margin-bottom: 0.5rem;
				}
			}
		}
	}

	&.is-style-bik-small-margin-columns {
		@media (min-width: 600px) and (max-width: 781px) {
			.wp-block-column:not(:only-child) {
				flex-basis: calc(50% - 0.5em) !important;
				flex-grow: 0;
			}
			.wp-block-column:nth-child(even) {
				margin-left: 1em;
			}
		}
		@media (min-width: 782px) {
			.wp-block-column:not(:first-child) {
				margin-left: 1em;
			}
		}
	}

	&.is-style-page-contact {
		.wp-block-column {
			hr {
				margin-top: 2.5rem;
				margin-bottom: 2.5rem;
			}
			iframe {
				max-width: none;
				width: 100%;
			}
			p:not(:last-child) a {
				color: var(--wp--preset--color--pink);
				font-weight: 400;
				text-decoration: underline;
				transition: color 0.2s ease-in-out;

				&:hover {
					color: var(--wp--preset--color--blue);
				}
			}
			p:last-child a {
				color: inherit;
				font-weight: inherit;
				text-decoration: none;
				transition: color 0.2s ease-in-out;

				&:hover {
					color: var(--wp--preset--color--pink);
				}
			}
			@media (max-width: 781px) {
				flex-basis: 100% !important;

				&:first-child {
					margin-bottom: 2.5em;
				}
			}
			@media (min-width: 782px) {
				.wp-block-group {
					height: 150px;
				}
			}
		}
		.wp-block-column:not(:first-child) {
			& > p:first-child {
				margin-bottom: 0;
			}
			.wp-block-media-text {
				padding: 0;

				&:first-child {
					margin-bottom: 2em;
				}
			}
			@media (min-width: 782px) {
				margin-left: 3em;
			}
		}
		@media (min-width: 600px) and (max-width: 781px) {
			.wp-block-column:nth-child(even) {
				margin-left: 0;
			}
		}
	}
}
