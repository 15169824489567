/* -------------------------------------------------------------- */
/* Stylesheet: HEADER Stylesheet ------------------ */
/* -------------------------------------------------------------- */

/* HOME ------------------------------------------- */
/* ------------------------------------------------ */
body.home .header {
	position: relative;
	height: 100vh;

	background-color: get-color(primary);

	@media (min-width: 800px) and (orientation: portrait) {
		max-height: 920px;
	}

	.skip-link {
		position: absolute;

		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);

		svg {
			width: 40px;
			height: 40px;
		}
	}
}

/* DEFAULT ---------------------------------------- */
/* ------------------------------------------------ */
body:not(.home) .header {
	width: 100%;
	max-width: 1440px;
	margin: calc(12.5% + 25px + 1rem) auto 0;

	@include breakpoint(large) {
		margin-top: calc(3.75em + 15px);
	}
}
