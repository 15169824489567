/* -------------------------------------------------------------- */
/* Stylesheet: MIDNIGHT Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.midnightHeader.blue {
	.logo {
		.blue {
			fill: $white;
		}

		.letter {
			fill: get-color(primary);
		}
	}

	.nav-wrapper .menu li a {
		color: get-color(primary);
	}

	.burger-content .burger .burger-svg .burger-inner {
		stroke: get-color(primary);
	}
}
.midnightInner {
	overflow: hidden !important;
}
