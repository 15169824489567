@mixin pink-circle-at-the-end() {
	position: relative;
	padding-right: rem-calc(20);

	&:before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12.88px' width='12.88px' viewbox='0 0 12.88 12.88'%3E%3Cpath d='M7.5,13.94A6.44,6.44,0,1,0,1.06,7.5,6.44,6.44,0,0,0,7.5,13.94' transform='translate(-1.06 -1.06)' fill='%23f5b5d3'/%3E%3C/svg%3E");
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

@mixin pink-circle-plus() {
	position: relative;

	&:after {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.12 35.12'%3E%3Cpath d='M18,35.56A17.56,17.56,0,1,0,.44,18,17.56,17.56,0,0,0,18,35.56' transform='translate(-0.44 -0.44)' fill='%23f5b5d3'/%3E%3Cpath d='M25.11,19.27H19.33V25H16.67V19.27H10.89V16.76h5.78V11.05h2.66v5.71h5.78Z' transform='translate(-0.44 -0.44)' fill='%23009ab2'/%3E%3C/svg%3E");
		position: absolute;
		pointer-events: none;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		height: 36px;
		width: 36px;

		opacity: 0;
		visibility: hidden;
		transition: all 300ms ease-in-out-sine;
	}

	&:hover:after {
		opacity: 1;
		visibility: visible;
	}
}

@mixin blue-filter() {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		pointer-events: none;

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: transparent;
		transition: background-color 250ms ease-in-out-sine;
	}

	&:hover:before {
		background-color: rgba($color: #009ab2, $alpha: 0.85);
	}
}
