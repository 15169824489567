/* -------------------------------------------------------------- */
/* Stylesheet: FOOTER Stylesheet ------------------ */
/* -------------------------------------------------------------- */
.footer {
	padding-top: 15px;
	margin-bottom: 15px;

	background-color: var(--wp--preset--color--pink);

	.widget-footer {
		padding: 0 1em;

		@include breakpoint(large) {
			padding: 0 4%;
		}

		@include breakpoint(xlarge) {
			max-width: 1280px;
			margin: 0 auto;

			padding: 0;
		}
	}

	/* FOOTER | CONTENT  ------------------------------ */
	/* ------------------------------------------------ */
	.widget-footer:first-of-type {
		h2 {
			color: get-color(primary);

			font-family: $body-font-family;
			font-size: rem-calc(18);
			font-weight: 500;
			line-height: 1.2;

			margin-bottom: 1rem;
			margin-top: 0;
		}

		.wp-block-columns {
			@media (max-width: 599px) {
				.wp-block-column:not(:first-child) {
					margin-top: 1em;
				}
			}
			@media (min-width: 600px) and (max-width: 781px) {
				.wp-block-column:not(:nth-child(2)):not(:first-child) {
					margin-top: 1em;
				}
			}

			/* FOOTER | CONTACT  ------------------------------ */
			/* ------------------------------------------------ */
			.wp-block-column:nth-of-type(2) {
				p,
				a {
					color: var(--wp--preset--color--white);
					font-weight: 500;

					margin-bottom: 0;

					padding: 0.25rem 0;
				}

				a:hover {
					color: var(--wp--preset--color--blue);
				}
			}

			/* FOOTER | SOCIAL MEDIA  ------------------------- */
			/* ------------------------------------------------ */
			.wp-block-column:nth-of-type(3) {
				ul {
					margin: 0 -0.75rem;

					a {
						color: var(--wp--preset--color--white);
						font-size: rem-calc(22);
						font-weight: 700;

						padding: 0.25rem 0.75rem;

						&:hover {
							color: var(--wp--preset--color--blue);
						}
					}
				}
			}
		}
	}

	/* FOOTER BIS ------------------------------------- */
	/* ------------------------------------------------ */
	.widget-footer:not(:first-of-type) {
		& ul {
			@include menu-base;
			justify-content: center;
			align-items: center;
			text-align: center;
			margin: 0 -10px 10px;

			@include breakpoint(medium) {
				flex-wrap: nowrap;
				justify-content: left;
				text-align: left;
			}

			li {
				position: relative;
				margin-bottom: 9px;

				a,
				.button {
					color: $white;
					transition: color 250ms ease-in-sine;

					font-size: rem-calc(15);
					font-weight: 600;

					padding: rem-calc(5 10);

					&:hover {
						color: get-color(primary);
					}
				}

				.button {
					background-color: transparent;
					border: 0;
				}

				&:after {
					content: '•';

					position: absolute;
					left: 50%;
					top: 100%;
					transform: translate(-50%, -50%);

					color: get-color(primary);

					font-family: $body-font-family;
					font-size: 20px;
					font-weight: 700;
					line-height: 1;
				}

				&:last-of-type {
					margin-bottom: 0;

					&:after {
						content: '';
					}
				}

				@include breakpoint(medium) {
					margin-right: 12px;
					margin-bottom: 0;

					&:after {
						left: auto;
						top: 50%;
						right: -12px;
						transform: translate(0, -40%);
					}

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}

	/* ------------------------------------------------ */

	/* POPUP ------------------------------------------ */
	/* ------------------------------------------------ */
	.dropdown-pane {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
}
