.wp-block-media-text {
  &.alignfull {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.has-background {
    padding: 1em;
  }

  a:focus img {
    outline-offset: -1px;
  }

  .wp-block-media-text__content {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  /**
	 * Block Options
	 */

  // Block Styles
  &.is-style-bik-auto-grid {
    margin-bottom: 1.2em;
    padding: 1em;

    @media (max-width: 600px) {
      grid-template-columns: 100% !important;
    }
    @media (min-width: 601px) {
      grid-template-columns: auto 1fr !important;
    }

    .wp-block-media-text__media {
      img {
        width: auto;
        max-width: 100%;
      }
    }

    &.is-stacked-on-mobile .wp-block-media-text__media {
      @media (max-width: 599px) {
        margin-bottom: 1em;
      }
    }
    
    .wp-block-media-text__content {
      padding: 0;

      @media (min-width: 600px) {
        padding-left: 2em;
      }
    }
  }
}
